@import 'src/styles/variables';
.table {
    width: 100%;
    overflow: auto;
    border-collapse: separate;
    border-spacing: 0 10px;
    &__headers {
        width: 100%;
        th {
            font-weight: 600;
            font-size: map-get($font-size, small);
            color: var(--color-text);
            padding: 15px 15px 0;
            flex: 1;
            &.icon {
                flex: 0;
                min-width: 70px;
            }
        }
    }
    &__columns {
        &__row {
            margin-top: 10px;
            background-color: var(--color-card);
            font-size: map-get($font-size, small);
            & > td {
                padding: 15px;
                min-width: 150px;
                flex: 1;
                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                &.icon {
                    flex: 0;
                    min-width: 30px;
                    width: 40px;
                    text-align: center;
                    img {
                        cursor: pointer;
                    }
                }
            }
        }
        &__expandedContent {
            padding: 10px;
            margin-left: 70px;
            &__title {
                font-size: map-get($font-size, regular);
                font-weight: 600;
            }
            &__table {
                font-size: map-get($font-size, small);
                margin-top: 15px;
                thead {
                    th {
                        font-weight: 600;
                        padding: 5px 10px 5px 0;
                    }
                }
                tbody {
                    td {
                        padding: 5px 10px 5px 0;
                    }
                }
            }
        }
        &__empty {
            font-size: map-get($font-size, tiny);
            text-align: center;
            background-color: var(--color-card);
            td {
                text-align: center;
                padding: 10px;
                border-radius: 15px;
            }
        }
    }
}
.wrapper {
    width: 100%;
    overflow: auto;
    @media (max-width: 2000px) {
        max-width: 1430px;
    }
}
@media (max-width: 1600px) {
    .wrapper {
        max-width: 1200px;
    }
}
@media (max-width: 1400px) {
    .wrapper {
        max-width: 1000px;
    }
}
@media (max-width: 1200px) {
    .wrapper {
        max-width: 800px;
    }
}
