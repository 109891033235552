@import "src/styles/variables";
.dropdown {
  position: relative;
  &__btn {
    padding: 6px 16px;
    border-radius: 15px;
    background-color: map-get($colors, green);
    @include flex(center, space-between);
    gap: 13px;
    cursor: pointer;
    &__content {
      span {
        color: white;
        display: block;
        &:first-child {
          font-size: map-get($font-size, tiny);
          font-weight: 400;
        }
      }
    }
    &.active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      img {
        transform: rotate(180deg);
      }
    }
  }
  &__content {
    max-height: 200px;
    overflow: auto;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: var(--background);
    &::-webkit-scrollbar {
      display: none;
    }
    &__heading {
      padding: 10px;
    }
    &__inner {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      & > div{
        padding: 6px 16px;
        background-color: var(--background);
        border-bottom: 1px solid rgba(map-get($colors, black), 0.2);
        color: var(--color-text);
        font-size: map-get($font-size, small);
        cursor: pointer;
        transition: 0.3s all;
        &:first-child {
          background-color: var(--background-item);
          color: var(--color-list-item);
          font-weight: 500;
        }
        &:hover {
          background-color: var(--background-item);
        }
        &:last-child {
          border-bottom: 0;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }
}

.customDropdown {
  label {
    font-weight: 500;
    font-size: map-get($font-size, tiny);
    color: #8B8E8E;
  }
  &__input {
    border-radius: 10px;
    cursor: pointer;
    padding: 11px 20px;
    color: var(--color-icon);
    border: 1px solid #8B8E8E;
    @include flex(center, space-between);
    background-color: var(--color-background);
    img {
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
  &__list {
    margin-top: 10px;
    border-radius: 10px;
    padding: 11px 20px;
    background-color: var(--color-background);
    overflow: auto;
    max-height: 200px;
    border: 1px solid #8B8E8E;
    &__item {
      @include flex(center, space-between);
      margin: 5px 0;
      color: var(--color-icon);
      cursor: pointer;
      padding: 5px 0;
      & > div {
        width: 25px;
      }
    }
  }
}

@media (max-width: 768px) {
  .dropdown {
    position: relative;
    &__content {
      z-index: 100;
      position: absolute;
      width: 100%;
    }
  }
}