@import "src/styles/variables";
.addSubaccount{
  &__form {

    &__info {
      @include flex(center, space-between);
      flex-wrap: wrap;
      margin-top: 20px;
      & > div {
        width: 45%;
        margin-bottom: 15px;
      }
    }
    &__optional{
      margin-top: 20px;
      &__title {
        text-align: center;
        font-size: map-get($font-size, tiny);
        @include flex(center, center);
        gap: 10px;
      }
      &__table {
        margin-top: 30px;
        table {
          thead {
            th {
              font-weight: 500;
              padding: 0 10px;
              font-size: map-get($font-size, small);
            }
          }
          tbody {
            tr {
              td {
                padding: 5px 10px;
                div {
                  &.flex {
                    @include flex(center);
                    gap: 10px;
                  }
                }
                &:first-child {
                  width: 20%;
                }
                &:nth-child(2) {
                  width: 25%;
                }
                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      &__add {
        @include flex(center, flex-end);
        margin-top: 5px;
        margin-right: 50px;
        .tiny {
          font-size: map-get($font-size, small) !important;
          padding: 5px 10px;
          gap: 0;
          border-radius: 5px;
        }
      }
    }
    &__error {
      font-size: map-get($font-size, small);
      color: map-get($colors, red) !important;
    }
    &__btn {
      text-align: center;
      margin-top: 20px;
    }
  }
}
.financial {
  text-align: center;
  margin-top: 50px;
  &__title {
    @include flex(center);
    gap: 20px;
    p {
      overflow-x: scroll;
    }
    div {
      cursor: pointer;
    }
  }
}
.logout {
  text-align: center;
  &__subtitle {
    margin-top: 30px;
    font-size: map-get($font-size, normal);
  }
  &__btns {
    margin-top: 20px;
    & > button {
      &:first-child {
        background-color: var(--background);
        border: 1px solid  var(--background);
        padding: 11px 35px;
        border-radius: 6px;
        font-weight: 600;
        margin-right: 20px;
      }
      &:last-child {
        background-color: var(--color-card);
        padding: 11px 35px;
        border-radius: 6px;
        border: 1px solid map-get($colors, black);
        font-weight: 600;
        margin-right: 20px;
      }
    }
  }
}
.editWallet {
  &__form {
    &__prev {
      margin: 20px 0;
      span {
        color: #7E8486;
        font-size: map-get($font-size, small);
      }
      margin-bottom: 20px;
    }
    &__warning {
      margin-top: 20px;
      margin-bottom: 20px;
      color: map-get($colors, orange);
      font-size: map-get($font-size, tiny);
      &__btn {
        cursor: pointer;
      }
    }
    &__btn {
      text-align: center;
    }
  }
}
.createSubAccount {
  padding: 10px;
  text-align: center;
  h2 {
    margin-top: 40px;
    color: map-get($colors, orange);
    font-size: map-get($font-size, normal);
    font-weight: 600;
  }
  p {
    font-size: map-get($font-size, small);
    margin-top: 20px;
  }
  &__btn {
    margin-top: 40px;
    padding: 10px;
    width: 100%;
  }
}