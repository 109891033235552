.custom--dropdown-container {
  text-align: left;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  color: #000;
  margin-bottom: 20px;
  background-color: #fff;

  .dropdown-input {
    padding: 27px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    gap: 7px;

    & .dropdown-selected-value.placeholder {
      color: #82868b;
    }

    & .dropdown-tool svg {
      transition: all 0.2s ease-in-out;
    }

    & .dropdown-tool svg.translate {
      transform: rotate(180deg);
    }
  }

  .dropdown-menu {
    width: 100%;
    left: 0;
    top: 95%;
    padding: 0 5px;
    position: absolute;
    transform: translateY(6px);
    border: 1px solid #000000;
    border-radius: 6px;
    overflow: auto;
    background-color: #101010;
    color: #fff;
    z-index: 99;
    max-height: 312px;
    min-height: 50px;
    scrollbar-width: thin;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.56);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #000000;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &.alignment--left {
      left: 0;
    }

    &.alignment--right {
      right: 0;
    }

    .dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      padding: 7px 10px;
      cursor: pointer;
      transition: background-color 0.35s ease;
      border-radius: 6px;
      font-weight: 500;

      h6{
        span{
          opacity: .5;
          font-size: 12px;
        }
      }
      p{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #3fbd65;
        padding: 5px;
        margin: 0;
        border-radius: 50%;
        aspect-ratio: 1 !important;
        width: 45px;
        height: 45px;
        opacity: .75;
      }

      &:hover {
        background-color: rgba(183, 206, 172, 0.05);
        color: #3fbe66;
      }

      &.selected {
        background-color: rgb(63, 190, 102);
        color: #ffffff;
        font-weight: 600;

        p{
          background-color: #fff;
          color: #3fbd65;
          font-weight: 600;
        }
      }


    }
    .dropdown-item-not-found{
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: center;
      font-size: 20px;
      padding: 40px 10px;
      cursor: default;

      &:hover{
        color: #fff;
        background-color: #101010;
      }
    }

    .search-box {
      position: sticky;
      top: 0;
      background-color: #101010;
      padding: 5px;
      z-index: 1;

      input {
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid #000000;
        border-radius: 5px;
        color: #fff;
        background-color: #242424;
      }
    }

    .dropdown-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .dropdown-tag-item {
        background-color: #3fbe66;
        color: #FFF;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 4px;
        border-radius: 6px;
        display: flex;
        align-items: center;

        .dropdown-tag-close {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }
    }
  }
}
