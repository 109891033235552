@charset "UTF-8";
@import '../shared/fonts/fonts.css';
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

a {
    text-decoration: none;
}
a:active,
a:hover {
    outline: 0;
}

ul,
li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}
*{
    scroll-behavior: smooth;
    scroll-snap-align: center;
}
html {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus {
    outline: 0;
}

img,
audio,
video {
    max-width: 100%;
    height: auto;
    border: 0;
}

audio,
canvas,
iframe,
video,
img,
svg {
    vertical-align: middle;
}

iframe {
    border: 0;
}

textarea {
    resize: none;
    /*remove the resize handle on the bottom right*/
    overflow: auto;
    vertical-align: top;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

input,
textarea,
select,
button {
    outline: none;
    border: none;
    font-size: 100%;
    margin: 0;
}

button,
input {
    line-height: normal;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input[type='search'] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input[type='checkbox'],
input[type='radio'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
    text-align: left;
}

.swiper > .swiper-button-prev,
.swiper > .swiper-button-next {
    display: none !important;
}

header {
    left: 0;
    width: 100%;
    z-index: 10;
}
@media (max-width: 767px) {
    header {
        top: 10px;
        z-index: 25;
    }
}
header .header-flex {
    background: white;
    border-radius: 20px;
    height: 86px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 2%;
    -webkit-box-shadow: 0 0 30px rgba(241, 245, 255, 0.6);
    box-shadow: 0 0 30px rgba(241, 245, 255, 0.6);
}
@media (min-width: 768px) and (max-width: 991px) {
    header .header-flex {
        height: 70px;
    }
}
@media (max-width: 767px) {
    header .header-flex {
        height: 62px;
        border-radius: 16px;
        padding: 6px 15px;
    }
}
@media (max-width: 767px) {
    header .container {
        padding: 0 10px;
    }
}
header .left-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 2vw;
}
header .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}
header .logo-box .logo {
    font-weight: bold;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
    header .logo-box .logo {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    header .logo-box .logo {
        font-size: 23px;
    }
}
header .logo-box .logo i {
    display: block;
    margin-right: 12px;
    width: 37px;
}
@media (min-width: 768px) and (max-width: 991px) {
    header .logo-box .logo i {
        margin-right: 7px;
    }
}
@media (max-width: 767px) {
    header .logo-box .logo i {
        width: 28px;
        margin-right: 9px;
    }
}
@media (max-width: 767px) and (max-width: 340px) {
    header .logo-box .logo i {
        margin-right: 5px;
    }
}
header .logo-box .logo img {
    width: 100%;
}
header .logo-box .logo span {
    display: block;
}
@media (min-width: 768px) and (max-width: 991px) {
    header .logo-box .logo span {
        padding-bottom: 10px;
    }
}
header .logo-box .slogan {
    display: block;
    font-size: 13px;
    line-height: 12px;
    color: rgba(53, 57, 86, 0.6);
    max-width: 45px;
    padding-left: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
    header .logo-box .slogan {
        position: absolute;
        left: 30px;
        bottom: 5px;
        max-width: none;
    }
}
@media (max-width: 767px) {
    header .logo-box .slogan {
        display: none;
    }
}
 .langs-wrap {
    position: relative;
}
.langs-drop, div .langs-drop {
    position: absolute;
    border-radius: 10px;
    top: 100%;
    width: 170px;
    margin-right: -20px;
    margin-top: 10px;
    right: 0;
    z-index: 10;
    padding: 1rem 1.5rem 1rem 1rem;
    background: var(--color-card);
    -webkit-box-shadow: 0 0 40px rgba(158, 192, 225, 0.3);
    box-shadow: 0 0 40px rgba(158, 192, 225, 0.3);
}
 .langs-drop:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    bottom: 100%;
    right: 45px;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
    -webkit-transform: none !important;
    transform: none !important;
}
@media (max-width: 767px) {
     .langs-drop {
        margin-top: 10px;
        //left: 0;
        margin-right: -33px;
    }
}
 .langs-drop li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 0 5px 0 10px;
}
 .langs-drop li:last-child {
    border: none;
}
 .langs-drop li a {
    font-size: 16px;
    color: var(--color-card-text);
    font-weight: 500;
    padding: 0.5rem 0;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
 .langs-drop li a:hover {
    color: #40c067;
}
 .langs-drop li.active {
    background: var(--color-active-text);
}
 .nav-col {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-left: 20px;
    border-left: 1px solid #e0e0e5;
}
@media (max-width: 767px) {
     .nav-col {
        display: none;
    }
}
 .nav-col nav {
    width: 100%;
}
 .nav-col .main-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 42px;
    max-width: 420px;
}
@media (min-width: 1400px) {
     .nav-col .main-nav {
        max-width: 550px;
    }
}
@media (min-width: 1908px) {
     .nav-col .main-nav {
        max-width: 550px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    header .nav-col .main-nav {
        max-width: 330px;
    }
}
header .nav-col .main-nav li a {
    height: 45px;
    padding: 0 10px;
    font-size: 22px;
    font-weight: bold;
    color: #000;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
    header .nav-col .main-nav li a {
        font-size: 19px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    header .nav-col .main-nav li a {
        font-size: 18px;
    }
}
header .nav-col .main-nav li a:hover {
    color: #40c067;
}
header .nav-col .main-nav li.active a {
    background: #476df2;
    color: #fff;
}
header .right-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 10px;
}
@media (max-width: 767px) {
    header .right-col {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-left: 1px solid rgba(138, 142, 174, 0.2);
        margin-left: 15px;
    }
}
header .right-col .links-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 767px) {
    header .right-col .links-list {
        max-width: 140px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    }
}
header .right-col .links-list .btn {
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid rgba(138, 142, 174, 0.2);
    padding: 0 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
    header .right-col .links-list .btn {
        padding: 0 15px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    header .right-col .links-list .btn {
        padding: 0 15px;
    }
}
@media (max-width: 767px) {
    header .right-col .links-list .btn {
        border: none;
        padding: 0;
        width: 40px;
        height: 38px;
        margin-left: 5px;
    }
}
header .right-col .links-list .btn:hover img {
    opacity: 0.7;
}
header .right-col .links-list .btn:hover span {
    color: #40c067;
}
header .right-col .links-list .btn.login-btn {
    border: none;
    padding-right: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
    header .right-col .links-list .btn.login-btn {
        padding-right: 10px;
    }
}
header .right-col .links-list .btn img {
    display: block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
header .right-col .links-list .btn span {
    display: block;
    font-weight: bold;
    color: #353956;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 17px;
    padding-left: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
    header .right-col .links-list .btn span {
        display: none;
    }
}
@media (max-width: 767px) {
    header .right-col .links-list .btn span {
        display: none;
    }
}
@media (max-width: 767px) {
    header .right-col .links-list .btn-switch-theme,
    header .right-col .links-list .search-btn {
        display: none;
    }
}
header .logined-user-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-left: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
    header .logined-user-box {
        margin-right: 5px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    header .logined-user-box {
        margin-left: 0;
    }
}
@media (max-width: 767px) {
    header .logined-user-box {
        padding-right: 40px;
        //border-right: 1px solid rgba(138, 142, 174, 0.2);
        margin-right: 5px;
    }
}
@media (max-width: 767px) and (max-width: 340px) {
    header .logined-user-box {
        padding-right: 35px;
    }
}
@media (max-width: 1024px) {
    header .logined-user-box:before {
        content: '';
        display: block;
        clear: both;
        position: absolute;
        top: 50%;
        right: 20px;
        display: inline-block;
        border: solid #40c067;
        border-width: 0 2px 2px 0;
        padding: 4px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin-top: 4px;
        opacity: 0;
    }
}
@media (max-width: 1024px) and (max-width: 340px) {
    header .logined-user-box:before {
        right: 15px;
    }
}
@media (max-width: 1024px) {
    header .logined-user-box:after {
        content: '';
        display: block;
        clear: both;
        position: absolute;
        top: 50%;
        right: -20px;
        display: inline-block;
        border: solid #40c067;
        border-width: 0 2px 2px 0;
        padding: 4px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: margin-top 0.3s ease;
        transition: margin-top 0.3s ease;
        margin-top: -7px;
    }
}
@media (max-width: 769px) {
    header .logined-user-box:after {
        right: 20px;
    }
}
@media (max-width: 768px) and (max-width: 340px) {
    header .logined-user-box:after {
        right: 15px;
    }
}
@media (max-width: 1024px) {
    header .logined-user-box.btn-actived:before {
        opacity: 1;
        margin-top: 2px;
    }
    header .logined-user-box.btn-actived:after {
        margin-top: -10px;
    }
}
header .logined-user-box .ava {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50px;
    min-width: 50px;
    height: 50px;
    position: relative;
    background: url(../cabinet/images/svg/user-default-ava.svg) no-repeat
        #d9ddeb 50% 50%;
    background-size: 33px 34px;
}
@media (min-width: 992px) and (max-width: 1199px) {
    header .logined-user-box .ava {
        min-width: 40px;
        width: 40px;
        height: 40px;
        background-size: 25px auto;
    }
}
@media (max-width: 1024px) {
    header .logined-user-box .ava {
        width: 38px;
        min-width: 38px;
        height: 38px;
        background-size: 26px 30px;
    }
}
header .logined-user-box .ava img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
header .logined-user-box .user-desc {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-left: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
    header .logined-user-box .user-desc {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    header .logined-user-box .user-desc {
        display: none;
    }
}
@media (max-width: 1024px) {
    header .logined-user-box .user-desc {
        display: none;
    }
}
header .logined-user-box .user-label {
    display: block;
    font-size: 13px;
    line-height: 13px;
    color: #999;
    font-weight: 300;
    margin-bottom: 2px;
}
header .logined-user-box .user-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 5px;
}
header .logined-user-box .user-name span {
    color: #fff;
    font-size: 17px;
    line-height: 15px;
    font-weight: 500;
    display: block;
}
header .logined-user-box .user-name i {
    border-radius: 50%;
    background: #40c067;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 17px;
    height: 17px;
    min-width: 17px;
    margin-left: 5px;
}
header .logined-user-box .user-name i img {
    width: 16px;
    margin-right: 6px;
    min-width: 16px;
}
header .logout-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 30px;
    margin-left: 10px;
    min-width: 30px;
    height: 50px;
}
@media (max-width: 767px) {
    header .logout-btn {
        display: none;
    }
}
header .logout-btn:hover img {
    -webkit-filter: invert(0.4);
    filter: invert(0.4);
}
header .logout-btn img {
    -webkit-filter: invert(0.7);
    filter: invert(0.7);
    width: 18px;
}
header .search-wrap {
    position: relative;
}
header .search-drop {
    position: absolute;
    border-radius: 10px;
    top: 100%;
    width: 300px;
    margin-right: -20px;
    margin-top: 10px;
    right: 0;
    padding: 1rem 1.5rem 1rem 1rem;
    background: #fff;
    -webkit-box-shadow: 0 0 40px rgba(158, 192, 225, 0.5);
    box-shadow: 0 0 40px rgba(158, 192, 225, 0.5);
}
header .search-drop:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    bottom: 100%;
    right: 45px;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
    -webkit-transform: none !important;
    transform: none !important;
}
header .search-drop .search-input-box {
    position: relative;
}
header .search-drop .search-input-box button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    min-width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
header .search-drop .search-input-box .form-control {
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
    border: 1px solid rgba(57, 108, 243, 0.3);
    padding-right: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
header .search-drop .search-input-box .form-control:hover,
header .search-drop .search-input-box .form-control:focus {
    border-color: rgba(57, 108, 243, 0.6);
}

.mobile-menu-btn {
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 38px;
    min-width: 38px;
    height: 38px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 50%;
    margin-left: 8px;
}
@media (min-width: 1025px) {
    .mobile-menu-btn {
        display: none;
    }
}
.mobile-menu-btn span {
    border: none;
    width: 22px;
    height: 3px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #fff;
    border-radius: 10px;
}
.mobile-menu-btn span:before,
.mobile-menu-btn span:after {
    content: '';
    display: block;
    clear: both;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    border-radius: 10px;
}
.mobile-menu-btn span:before {
    top: -7px;
    -webkit-transition-property:
        top,
        -webkit-transform;
    transition-property:
        top,
        -webkit-transform;
    transition-property: top, transform;
    transition-property:
        top,
        transform,
        -webkit-transform;
}
.mobile-menu-btn span:after {
    bottom: -7px;
    -webkit-transition-property:
        bottom,
        -webkit-transform;
    transition-property:
        bottom,
        -webkit-transform;
    transition-property: bottom, transform;
    transition-property:
        bottom,
        transform,
        -webkit-transform;
}
.mobile-menu-btn.btn-actived {
    background: black;
}
.mobile-menu-btn.btn-actived:before {
    opacity: 1;
    visibility: visible;
    -webkit-transform: rotate(-45deg) translate(0px, 0);
    transform: rotate(-45deg) translate(0px, 0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
.mobile-menu-btn.btn-actived span {
    background: transparent;
}
.mobile-menu-btn.btn-actived span:before {
    background: #fff;
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.mobile-menu-btn.btn-actived span:after {
    background: #fff;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100%;
}

.mobile-header-inside {
    display: none;
    top: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: max-content;
    height: fit-content;
    max-height: 50%;
    position: fixed;
    margin-top: 84px;
    z-index: 50;
    padding: 3%;
    background-color: #141318;
    color: #fff;
    overflow-y: auto;
}
@media (max-width: 1024px) {
    .mobile-header-inside {
        display: block;
    }
}
.mobile-header-inside .inside-holder {
    position: relative;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    gap: 15px;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: stretch;
}
.mobile-header-inside .head-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.mobile-header-inside .head-top hr {
    width: 100%;
    border-color: #9fb1d4;
}
@media (orientation: landscape) {
    .mobile-header-inside .head-top ul {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
.mobile-header-inside .head-top ul li {
    position: relative;
    display: block;
    width: 100%;
}
@media (orientation: landscape) {
    .mobile-header-inside .head-top ul li {
        width: 50%;
    }
}
.mobile-header-inside .head-top ul li a {
    color: inherit;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    display: block;
    padding: 15px 5px;
    border-radius: 7px;
}
.mobile-header-inside .head-top ul li a:hover{
    background-color: rgba(64, 192, 103, 0.5);
}
.mobile-header-inside .head-top ul li.active a,
.mobile-header-inside .head-top ul li.current-menu-item a {
    color: #40c067;
}
.mobile-header-inside .head-top ul li.active a span:before,
.mobile-header-inside .head-top ul li.current-menu-item a span:before {
    width: 100%;
}
.mobile-header-inside .head-bottom {
    position: relative;

}
.mobile-header-inside .head-bottom .nav-group {
    display: flex;
    //justify-content: space-around;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .mobile__menu__auth__button{
        display: inline-block;
        padding: 10px 25px;
        color: #fff;
        border: 2px solid #fff;
        font-weight: 600;
        font-size: 15px;
        border-radius: 10px;

        &._active{
            background-color: #fff;
            color: #141318;

        }

        &:hover{
            background-color: #40c067;
            color: #ffffff;
            border-color: #40c067;
        }
    }
}
.mobile-header-inside .head-bottom .nav-group:last-child {
    margin-bottom: 0;
}
.mobile-header-inside .head-bottom .nav-label {
    display: block;
    font-size: 15px;
    color: #9aa8c5;
    font-weight: 300;
    margin-bottom: 4px;
}
.mobile-header-inside ._gCustomSelect .selectric {
    border-radius: 9px;
    background: #fff;
    border: 1px solid rgba(202, 204, 222, 0.63);
}
.mobile-header-inside ._gCustomSelect .selectric:hover {
    border-color: #40c067;
}
.mobile-header-inside ._gCustomSelect .selectric .label {
    height: 45px;
    margin-left: 18px;
    margin-right: 40px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    color: #060d45;
}
.mobile-header-inside ._gCustomSelect .selectric .button {
    height: auto;
    bottom: 0;
    right: 10px;
    width: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
}
.mobile-header-inside ._gCustomSelect .selectric .button:before {
    content: '';
    display: block;
    clear: both;
    display: inline-block;
    border: solid #060d45;
    border-width: 0 1px 1px 0;
    padding: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.mobile-header-inside ._gCustomSelect .selectric .button:after {
    display: none;
}
.mobile-header-inside .search-form .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}
.mobile-header-inside .search-form .icon {
    position: absolute;
    left: 0;
    width: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    bottom: 0;
    padding-left: 5px;
}
.mobile-header-inside .search-form .form-control {
    height: 45px;
    border-color: #cfd8ea;
    color: #000000;
    border-radius: 9px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    padding: 0 90px 2px 50px;
    font-size: 17px;
    font-weight: 300;
}
.mobile-header-inside .search-form .form-control::-webkit-input-placeholder {
    color: #9aa8c5;
}
.mobile-header-inside .search-form .form-control:-moz-placeholder {
    color: #9aa8c5;
}
.mobile-header-inside .search-form .form-control::-moz-placeholder {
    color: #9aa8c5;
}
.mobile-header-inside .search-form .form-control:-ms-input-placeholder {
    color: #9aa8c5;
}
.mobile-header-inside .search-form .form-control:focus {
    border-color: #40c067;
}
.mobile-header-inside .search-form ._g-blue-btn {
    height: 45px;
    width: 81px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 9px;
    padding-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
.mobile-header-inside .fake-radio-btn {
    background: #eff3fe;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 9px;
    cursor: pointer;
}
.mobile-header-inside .fake-radio-btn.theme-dark .theme-btn:last-child {
    background: #fff;
    color: #000;
    -webkit-box-shadow: 0px 4px 14px rgba(90, 113, 156, 0.08);
    box-shadow: 0px 4px 14px rgba(90, 113, 156, 0.08);
}
.mobile-header-inside .fake-radio-btn .theme-btn {
    width: 50%;
    border-radius: 9px;
    font-size: 18px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #40c067;
}
.mobile-header-inside .fake-radio-btn .theme-btn:first-child {
    background: #fff;
    color: #000;
    -webkit-box-shadow: 0px 4px 14px rgba(90, 113, 156, 0.08);
    box-shadow: 0px 4px 14px rgba(90, 113, 156, 0.08);
}
.mobile-header-inside .search-form {
    padding-top: 10px;
}

html,
body {
    height: 100%;
}
@media (max-width: 767px) {
    html,
    body {
        height: auto;
    }
}

body {
    font-size: 16px;
    width: 100%;
    font-family: 'Aeroport_webfont', Helvetica, Arial, sans-serif;
    color: #060d45;
    -webkit-font-smoothing: antialiased;
    background: #server;
}

a {
    text-decoration: none;
}

b,
strong,
h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

body ._gCustomScroll .scrollbar-track-y,
body ._gCustomScrollNOXS .scrollbar-track-y,
body .selectric-scroll .scrollbar-track-y {
    width: 2px !important;
    opacity: 1;
    border-radius: 2px;
    overflow: hidden;
    background: none !important;
}
body ._gCustomScroll .scrollbar-track-y .scrollbar-thumb,
body ._gCustomScrollNOXS .scrollbar-track-y .scrollbar-thumb,
body .selectric-scroll .scrollbar-track-y .scrollbar-thumb {
    width: 3px !important;
    border-radius: 2px;
    background: #5c48b5 !important;
}
body ._gCustomScroll .scrollbar-track-y .scrollbar-thumb:after,
body ._gCustomScrollNOXS .scrollbar-track-y .scrollbar-thumb:after,
body .selectric-scroll .scrollbar-track-y .scrollbar-thumb:after {
    display: none;
}
body ._gCustomScroll.hasScroll,
body ._gCustomScrollNOXS.hasScroll,
body .selectric-scroll.hasScroll {
    padding-right: 25px;
}

.abs-link {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    display: block;
    overflow: hidden;
    text-indent: -9999px;
}

button {
    outline: none;
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    display: inline-block;
}

body div.scrollbar-track-y {
    background-color: transparent !important;
    width: 25px !important;
    z-index: 50 !important;
}
body div.scrollbar-track-y .scrollbar-thumb {
    background: none !important;
    border-radius: 30px;
    bottom: 0px;
    left: 0;
    top: 0px;
    width: 100%;
}
body div.scrollbar-track-y .scrollbar-thumb:hover:after {
    background: #666;
}
body div.scrollbar-track-y .scrollbar-thumb:after {
    content: '';
    display: block;
    clear: both;
    background-color: #ddd;
    border-radius: 3px;
    bottom: 0;
    left: 50%;
    position: absolute;
    top: 0;
    width: 6px;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
}
body div.scrollbar-track-y .scrollbar-thumb:hover {
    background-color: #ccc;
}

img.full-width {
    width: 100%;
}

@media (min-width: 768px) {
    .all-page-wrapper {
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        min-height: 100vh;
    }
    .all-page-wrapper .all-page-holder {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }
}

@media (min-width: 1400px) {
    ._g-plefted {
        padding: 0 30px;
    }
}

.rel-box {
    position: relative;
    z-index: 3;
    margin-top: 20px;
    min-height: 100px;
}

.global-close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: none;
    outline: none;
    border: none;
    z-index: 5;
}
@media (max-width: 767px) {
    .global-close-btn {
        top: 5px;
        right: 15px;
        //left: -10px;
        width: 14px;
        height: 14px;
    }
}
.global-close-btn:before,
.global-close-btn:after {
    content: '';
    display: block;
    clear: both;
    height: 2px;
    background-color: #8a8eae;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    width: 135%;
    top: 0;
    margin-top: 8px;
    margin-left: -3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.global-close-btn:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.global-close-btn:hover:before,
.global-close-btn:hover:after {
    background: #ccc;
}

._g-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 767px) {
    .no-xs {
        display: none !important;
    }
}

html.modal-open,
body.modal-open {
    overflow: hidden;
}

.modal-open {
    overflow: hidden;
}
.modal-open body {
    overflow: hidden !important;
}

.jsTooltipWrap {
    position: relative;
}
.jsTooltipWrap .tooltip {
    opacity: 1;
    width: 100%;
    left: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
    top: auto !important;
    bottom: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-align: right;
    margin-bottom: 5px !important;
}
.jsTooltipWrap .tooltip .tooltip-inner {
    background: #000;
    width: auto;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: left;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 13px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
}
.jsTooltipWrap .tooltip .tooltip-inner b {
    color: #d61616;
}
.jsTooltipWrap .tooltip .tooltip-arrow {
    position: absolute;
    top: 100% !important;
    right: 30px !important;
    left: auto !important;
    margin-left: -9px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    -webkit-transform: none !important;
    transform: none !important;
}

.arrowed-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.arrowed-link:hover {
    color: #40c067;
}
.arrowed-link:hover img {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
@media (max-width: 767px) {
    .arrowed-link {
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        color: #40c067;
    }
}
.arrowed-link i {
    width: 25px;
    margin-left: 45px;
    display: block;
}
@media (max-width: 767px) {
    .arrowed-link i {
        margin-left: 20px;
        width: 20px;
    }
}
.arrowed-link i img {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-filter: invert(1);
    filter: invert(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
@media (max-width: 767px) {
    .arrowed-link i img {
        -webkit-filter: invert(12%) sepia(93%) saturate(3898%)
            hue-rotate(339deg) brightness(103%) contrast(112%);
        filter: invert(12%) sepia(93%) saturate(3898%) hue-rotate(339deg)
            brightness(103%) contrast(112%);
    }
}

._g-full-page-btn {
    background: #40c067;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 15px;
    height: 100px;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 34px;
}
@media (max-width: 767px) {
    ._g-full-page-btn {
        height: 50px;
        font-size: 17px;
        text-align: center;
    }
}
._g-full-page-btn:hover {
    background: #3c5fd4;
}
._g-full-page-btn i {
    display: block;
    padding-left: 60px;
}
@media (max-width: 767px) {
    ._g-full-page-btn i {
        padding-left: 0;
        margin-left: 10px;
        width: 25px;
    }
    ._g-full-page-btn i img {
        width: 100%;
    }
}

._g-blue-circle-link {
    border-radius: 50%;
    width: 180px;
    min-width: 180px;
    height: 180px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    background: #40c067;
    font-size: 20px;
    line-height: 20px;
    position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
    ._g-blue-circle-link {
        width: 150px;
        height: 150px;
        min-width: 150px;
    }
}
._g-blue-circle-link:before {
    content: '';
    display: block;
    clear: both;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #40c067;
    border-radius: 50%;
}
._g-blue-circle-link span {
    display: block;
    position: relative;
    text-align: center;
}
._g-blue-circle-link:hover:before {
    background: #3c5fd4;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.xs-collapse-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding: 12px 15px 12px 0;
    font-size: 22px;
    line-height: 20px;
    font-weight: normal;
    color: #060d45;
    position: relative;
}
@media (min-width: 768px) {
    .xs-collapse-btn {
        display: none;
    }
}
.xs-collapse-btn i {
    position: relative;
    width: 22px;
    height: 22px;
    display: block;
    min-width: 22px;
    margin-right: 13px;
}
.xs-collapse-btn i:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    width: 100%;
    background: #40c067;
    height: 2px;
    border-radius: 1px;
}
.xs-collapse-btn i:after {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    top: 0;
    bottom: 0;
    background: #40c067;
    border-radius: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.xs-collapse-btn span {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.xs-collapse-btn em {
    font-style: normal;
    margin-left: auto;
    width: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.xs-collapse-btn em img {
    max-width: 40px;
}
.xs-collapse-btn.active-btn {
    color: #000;
}
.xs-collapse-btn.active-btn i:after {
    opacity: 0;
}

@media (max-width: 767px) {
    .no-xs {
        display: none !important;
    }
}

._g-blue-btn {
    background: #40c067;
    font-size: 32px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 88px;
    border-radius: 14px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 40px 5px;
}
@media (max-width: 767px) {
    ._g-blue-btn {
        height: 54px;
        font-size: 17px;
        padding: 0 20px 2px;
    }
}
._g-blue-btn i {
    margin-left: 25px;
}
@media (max-width: 767px) {
    ._g-blue-btn i {
        max-width: 30px;
        margin-left: 15px;
    }
}
._g-blue-btn:hover {
    background: #3c5fd4;
}
@media (min-width: 768px) {
    ._g-blue-btn.size-m {
        height: 62px;
        font-size: 23px;
    }
}
._g-blue-btn.size-s {
    height: 40px;
    font-size: 14px;
    padding: 0 20px;
}
._g-blue-btn.size-s i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 25px;
    margin-left: 15px;
}
@media (min-width: 768px) {
    ._g-blue-btn._size-s-one {
        height: 58px;
        font-size: 19px;
        padding: 0 20px;
    }
}

._g-gray-btn {
    background: #e9effe;
    font-size: 32px;
    color: #40c067;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 88px;
    border-radius: 14px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 40px 5px;
    font-weight: bold;
}
@media (max-width: 767px) {
    ._g-gray-btn {
        height: 54px;
        font-size: 17px;
        padding: 0 20px 2px;
    }
}
._g-gray-btn i {
    margin-left: 25px;
}
._g-gray-btn:hover {
    background: #40c067;
    color: #fff;
}
._g-gray-btn:hover i {
    -webkit-filter: grayscale(1) brightness(3);
    filter: grayscale(1) brightness(3);
}
._g-gray-btn.size-m {
    height: 67px;
    font-size: 23px;
}
._g-gray-btn.size-m i {
    margin-left: 15px;
}
@media (min-width: 768px) {
    ._g-gray-btn._size-s-one {
        height: 58px;
        padding: 0 20px;
        font-size: 19px;
    }
}

.out-of-content {
    margin-left: -98px;
}
@media (min-width: 768px) and (max-width: 991px) {
    .out-of-content {
        margin-left: 0;
    }
}
@media (max-width: 767px) {
    .out-of-content {
        margin: 0;
    }
}
.out-of-content .out-holder {
    margin-right: -4%;
}
.out-of-content .out-frame {
    margin-right: -280px;
}
@media (min-width: 768px) and (max-width: 991px) {
    .out-of-content .out-frame {
        margin-right: -200px;
    }
}
@media (max-width: 767px) {
    .out-of-content .out-frame {
        margin: 0;
    }
}

._g-choiceCoinSelect .selectric {
    border-radius: 12px;
    background: none;
    border: 1px solid #b0c4fa;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelect .selectric {
        border-radius: 7px;
    }
}
._g-choiceCoinSelect .selectric:hover {
    border-color: #40c067;
}
._g-choiceCoinSelect .selectric .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
    margin-left: 15px;
    margin-right: 55px;
    font-size: 28px;
    line-height: 28px;
    color: #060d45;
}
@media (min-width: 1400px) {
    ._g-choiceCoinSelect .selectric .label {
        margin-right: 68px;
        font-size: 32px;
        line-height: 32px;
        margin-left: 25px;
    }
}
@media (max-width: 767px) {
    ._g-choiceCoinSelect .selectric .label {
        height: 48px;
        font-size: 19px;
        line-height: 19px;
    }
}
._g-choiceCoinSelect .selectric .label img {
    border-radius: 50%;
    margin-right: 15px;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelect .selectric .label img {
        margin-right: 10px;
        max-width: 30px;
    }
}
._g-choiceCoinSelect .selectric .label span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
._g-choiceCoinSelect .selectric .button {
    height: auto;
    bottom: 0;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 10px;
    background: none;
}
@media (min-width: 1400px) {
    ._g-choiceCoinSelect .selectric .button {
        width: 60px;
    }
}
@media (max-width: 767px) {
    ._g-choiceCoinSelect .selectric .button {
        width: 40px;
    }
}
._g-choiceCoinSelect .selectric .button:before {
    content: '';
    display: block;
    clear: both;
    display: inline-block;
    border: solid #060d45;
    border-width: 0 1px 1px 0;
    padding: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
@media (max-width: 767px) {
    ._g-choiceCoinSelect .selectric .button:before {
        padding: 3px;
    }
}
._g-choiceCoinSelect .selectric .button:after {
    display: none;
}
._g-choiceCoinSelect .selectric-open .selectric {
    border-color: #40c067;
}
._g-choiceCoinSelect .selectric-open .selectric .button:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    border-color: #40c067;
}
._g-choiceCoinSelect .selectric-open.selectric-above .selectric-items {
    margin-top: 0;
    margin-bottom: 10px;
}
._g-choiceCoinSelect .selectric-items {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    margin-top: 10px;
    border: none;
    -webkit-box-shadow: 10px 11px 35px rgba(112, 123, 154, 0.2);
    box-shadow: 10px 11px 35px rgba(112, 123, 154, 0.2);
}
._g-choiceCoinSelect .selectric-items .selectric-scroll .scrollbar-track-y {
    right: 12px;
    top: 10px;
    width: 3px !important;
    bottom: 10px;
    height: auto;
    background: rgba(112, 112, 112, 0.2) !important;
}
._g-choiceCoinSelect
    .selectric-items
    .selectric-scroll
    .scrollbar-track-y
    .scrollbar-thumb {
    background: #476df2 !important;
}
._g-choiceCoinSelect .selectric-items ul {
    padding: 7px;
}
._g-choiceCoinSelect .selectric-items ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 26px;
    line-height: 26px;
    color: #060d45;
    border-radius: 9px;
    padding: 7px 10px 7px 27px;
}
._g-choiceCoinSelect .selectric-items ul li img {
    display: block;
    margin-right: 19px;
    border-radius: 50%;
    max-width: 40px;
}
._g-choiceCoinSelect .selectric-items ul li span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: block;
}
._g-choiceCoinSelect .selectric-items ul li:hover {
    background: none;
    color: #476df2;
}
._g-choiceCoinSelect .selectric-items ul li.selected {
    background: #f0f7ff;
    color: #060d45;
}

._g-choiceCoinSelectHash .selectric {
    border-radius: 12px;
    background: none;
    border: 1px solid #caccde;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash .selectric {
        border-radius: 10px;
    }
}
._g-choiceCoinSelectHash .selectric:hover {
    border-color: #40c067;
}
._g-choiceCoinSelectHash .selectric .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    margin-left: 15px;
    margin-right: 55px;
    font-size: 24px;
    line-height: 28px;
    color: #060d45;
}
@media (min-width: 1400px) {
    ._g-choiceCoinSelectHash .selectric .label {
        margin-right: 68px;
        font-size: 32px;
        line-height: 32px;
        margin-left: 25px;
    }
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash .selectric .label {
        height: 50px;
        margin-left: 10px;
        font-size: 20px;
        line-height: 22px;
        margin-right: 40px;
    }
}
._g-choiceCoinSelectHash .selectric .label img {
    border-radius: 50%;
    margin-right: 12px;
    width: 38px;
    max-width: 38px;
}
._g-choiceCoinSelectHash .selectric .label span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: 500;
    letter-spacing: -1px;
}
._g-choiceCoinSelectHash .selectric .label i {
    font-style: normal;
    font-size: 19px;
    font-weight: normal;
    margin-left: 12px;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash .selectric .label i {
        font-size: 16px;
    }
}
._g-choiceCoinSelectHash .selectric .button {
    height: auto;
    bottom: 0;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 10px;
    background: none;
}
@media (min-width: 1400px) {
    ._g-choiceCoinSelectHash .selectric .button {
        width: 60px;
    }
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash .selectric .button {
        width: 40px;
        right: 0;
    }
}
._g-choiceCoinSelectHash .selectric .button:before {
    content: '';
    display: block;
    clear: both;
    display: inline-block;
    border: solid #060d45;
    border-width: 0 2px 2px 0;
    padding: 5px;
    margin-bottom: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash .selectric .button:before {
        padding: 3px;
    }
}
._g-choiceCoinSelectHash .selectric .button:after {
    display: none;
}
._g-choiceCoinSelectHash .selectric-open .selectric {
    border-color: #40c067;
}
._g-choiceCoinSelectHash .selectric-open .selectric .button:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    border-color: #40c067;
    margin-bottom: 0;
}
._g-choiceCoinSelectHash .selectric-open.selectric-above .selectric-items {
    margin-top: 0;
    margin-bottom: 10px;
}
._g-choiceCoinSelectHash .selectric-items {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    margin-top: 10px;
    border: none;
    -webkit-box-shadow: 10px 11px 35px rgba(112, 123, 154, 0.2);
    box-shadow: 10px 11px 35px rgba(112, 123, 154, 0.2);
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash .selectric-items {
        border-radius: 8px;
    }
}
._g-choiceCoinSelectHash .selectric-items .selectric-scroll .scrollbar-track-y {
    right: 12px;
    top: 10px;
    width: 3px !important;
    bottom: 10px;
    height: auto;
    background: rgba(112, 112, 112, 0.2) !important;
}
._g-choiceCoinSelectHash
    .selectric-items
    .selectric-scroll
    .scrollbar-track-y
    .scrollbar-thumb {
    background: #476df2 !important;
}
._g-choiceCoinSelectHash .selectric-items ul {
    padding: 7px;
}
._g-choiceCoinSelectHash .selectric-items ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 24px;
    line-height: 26px;
    color: #060d45;
    border-radius: 9px;
    padding: 7px 10px 7px 19px;
}
._g-choiceCoinSelectHash .selectric-items ul li img {
    display: block;
    margin-right: 19px;
    border-radius: 50%;
    max-width: 40px;
}
._g-choiceCoinSelectHash .selectric-items ul li span {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: 500;
    letter-spacing: -1px;
}
._g-choiceCoinSelectHash .selectric-items ul li i {
    font-style: normal;
    font-size: 19px;
    font-weight: normal;
    margin-left: 12px;
}
._g-choiceCoinSelectHash .selectric-items ul li:hover {
    background: none;
    color: #476df2;
}
._g-choiceCoinSelectHash .selectric-items ul li.selected {
    background: #f0f7ff;
    color: #060d45;
}
._g-choiceCoinSelectHash._mod-amount .selectric .label {
    margin-right: 50px;
    margin-left: 15px;
    font-size: 22px;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash._mod-amount .selectric .label {
        font-size: 20px;
    }
}
._g-choiceCoinSelectHash._mod-amount .selectric .label span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
._g-choiceCoinSelectHash._mod-amount .selectric .label span i {
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash._mod-amount .selectric .label span i {
        font-size: 14px;
    }
}
._g-choiceCoinSelectHash._mod-amount .selectric .label span em {
    font-style: normal;
    padding-left: 5px;
}
._g-choiceCoinSelectHash._mod-amount .selectric .button {
    width: 45px;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash._mod-amount .selectric .button {
        width: 40px;
    }
}
._g-choiceCoinSelectHash._mod-amount .selectric .button:before {
    padding: 4px;
}
@media (max-width: 767px) {
    ._g-choiceCoinSelectHash._mod-amount .selectric .button:before {
        padding: 3px;
    }
}
._g-choiceCoinSelectHash._mod-amount .selectric-items ul li {
    font-size: 22px;
    padding-right: 20px;
    padding-left: 10px;
}
._g-choiceCoinSelectHash._mod-amount .selectric-items ul li span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
._g-choiceCoinSelectHash._mod-amount .selectric-items ul li span i {
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
}
._g-choiceCoinSelectHash._mod-amount .selectric-items ul li span em {
    font-style: normal;
    padding-left: 5px;
}

._gCustomSelect .selectric {
    border-radius: 8px;
    background: none;
    border: 1px solid rgba(202, 204, 222, 0.63);
}
._gCustomSelect .selectric:hover {
    border-color: #40c067;
}
._gCustomSelect .selectric .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 53px;
    margin-left: 18px;
    margin-right: 40px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #060d45;
}
@media (max-width: 767px) {
    ._gCustomSelect .selectric .label {
        height: 48px;
    }
}
._gCustomSelect .selectric .label img {
    border-radius: 50%;
    margin-right: 15px;
}
._gCustomSelect .selectric .label span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
._gCustomSelect .selectric .button {
    height: auto;
    bottom: 5px;
    right: 10px;
    width: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
}
._gCustomSelect .selectric .button:before {
    content: '';
    display: block;
    clear: both;
    display: inline-block;
    border: solid #060d45;
    border-width: 0 1px 1px 0;
    padding: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
._gCustomSelect .selectric .button:after {
    display: none;
}
._gCustomSelect .selectric-open .selectric {
    border-color: #40c067;
}
._gCustomSelect .selectric-open .selectric .button:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    border-color: #40c067;
}
._gCustomSelect .selectric-open.selectric-above .selectric-items {
    margin-top: 0;
    margin-bottom: 10px;
}
._gCustomSelect .selectric-items {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    margin-top: 10px;
    border: none;
    -webkit-box-shadow: 10px 11px 35px rgba(112, 123, 154, 0.2);
    box-shadow: 10px 11px 35px rgba(112, 123, 154, 0.2);
}
._gCustomSelect .selectric-items .selectric-scroll .scrollbar-track-y {
    right: 12px;
    top: 10px;
    width: 3px !important;
    bottom: 10px;
    height: auto;
    background: rgba(112, 112, 112, 0.2) !important;
}
._gCustomSelect
    .selectric-items
    .selectric-scroll
    .scrollbar-track-y
    .scrollbar-thumb {
    background: #476df2 !important;
}
._gCustomSelect .selectric-items ul {
    padding: 7px;
}
._gCustomSelect .selectric-items ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    color: #060d45;
    border-radius: 9px;
    padding: 7px 10px 7px 15px;
}
._gCustomSelect .selectric-items ul li img {
    display: block;
    margin-right: 19px;
    border-radius: 50%;
    max-width: 40px;
}
._gCustomSelect .selectric-items ul li span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: block;
}
._gCustomSelect .selectric-items ul li:hover {
    background: none;
    color: #476df2;
}
._gCustomSelect .selectric-items ul li.selected {
    background: #f0f7ff;
    color: #060d45;
}
._g-plefted-xlg .content {
    font-size: 20px;
    margin-top: 20px;
    p {
        padding-left: 10px;
        margin: 0 !important;
        line-height: 25px;
    }
    ul {
        padding-left: 20px;
        li {
            list-style-type: disc;
            padding-left: 5px;
        }
    }
    table {
        border: 1px solid lightgray;
        padding: 5px;
        margin: 20px 0;
        thead,
        tbody {
            th {
                border: 1px solid lightgray;
                padding: 5px;
            }
            td {
                padding: 5px;
                border: 1px solid lightgray;
                padding-right: 40px;
            }
        }
    }
    h4 {
        font-weight: 700;
        font-size: 20px;
    }
}
@media (min-width: 1400px) {
    ._g-plefted-xlg {
        padding-left: 2vw;
        padding-right: 2vw;
        .content {
            font-size: 16px;
        }
    }
}

@media (max-width: 768px) {
    ._g-plefted-xlg {
        .content {
            font-size: 16px;
        }
    }
}

._g-warn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
._g-warn-box i {
    border-radius: 50%;
    width: 18px;
    min-width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #8a8eae;
    font-style: normal;
    font-size: 14px;
    padding-bottom: 2px;
    font-weight: bold;
    color: #8a8eae;
}
._g-warn-box span {
    display: block;
    padding-left: 13px;
    color: #5a5e80;
    font-size: 16px;
    line-height: 17px;
}

._g-radio-custom-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    height: 53px;
    width: auto;
}
@media (max-width: 767px) {
    ._g-radio-custom-row {
        height: 34px;
        border-radius: 7px;
    }
}
._g-radio-custom-row .radio-item {
    position: relative;
    text-align: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
._g-radio-custom-row .radio-item input {
    opacity: 0;
    font-size: 0;
    visibility: hidden;
    position: absolute;
}
._g-radio-custom-row .radio-item input:checked + .radio-holder {
    background: #fff;
    -webkit-box-shadow: 0 4px 16px -7px rgba(0, 0, 0, 0.16);
    box-shadow: 0 4px 16px -7px rgba(0, 0, 0, 0.16);
    cursor: default;
    color: #000;
}
._g-radio-custom-row .radio-item .radio-item-label {
    margin: 0;
    height: 100%;
    display: block;
    font-weight: normal;
    font-size: 24px;
}
@media (max-width: 767px) {
    ._g-radio-custom-row .radio-item .radio-item-label {
        font-size: 14px;
    }
}
._g-radio-custom-row .radio-item .radio-holder {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 17px;
    border-radius: 7px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.34);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
@media (max-width: 767px) {
    ._g-radio-custom-row .radio-item .radio-holder {
        padding: 0 16px;
        border-radius: 5px;
    }
}
._g-radio-custom-row .radio-item .radio-holder:hover {
    color: #060d45;
}

._g-blue-circle-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    color: #40c067;
    font-weight: bold;
    width: 130px;
    height: 130px;
    min-width: 130px;
    text-align: center;
    border: 2px solid #40c067;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
@media (max-width: 767px) {
    ._g-blue-circle-btn {
        width: 86px;
        height: 86px;
        min-width: 86px;
        font-size: 12px;
        line-height: 12px;
    }
}
._g-blue-circle-btn:hover {
    background: #40c067;
    color: #fff;
}

._g-custom-checkbox {
    position: relative;
    font-weight: normal;
}
._g-custom-checkbox input {
    opacity: 0;
    font-size: 0;
    visibility: hidden;
    position: absolute;
}
._g-custom-checkbox input:checked + .checkbox-holder + .ch-btn {
    border-color: #40c067;
}
._g-custom-checkbox input:checked + .checkbox-holder + .ch-btn:before {
    opacity: 1;
}
._g-custom-checkbox:hover .ch-btn {
    border-color: #40c067;
}
._g-custom-checkbox .checkbox-label {
    margin: 0;
    height: 100%;
    display: block;
    font-weight: normal;
    font-size: 16px;
}
._g-custom-checkbox .ch-btn {
    position: absolute;
    width: 30px;
    height: 28px;
    border: 1px solid rgba(57, 108, 243, 0.4);
    border-radius: 8px;
    left: 0;
    top: 0;
    cursor: pointer;
}
._g-custom-checkbox .ch-btn:before {
    content: '';
    display: block;
    clear: both;
    display: inline-block;
    border: solid #40c067;
    border-width: 0 2px 2px 0;
    padding: 6px 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 10px;
    opacity: 0;
}
._g-custom-checkbox .checkbox-holder {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 3px 17px 0 46px;
    border-radius: 7px;
    cursor: pointer;
    color: #7a7d93;
    font-weight: 300;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
@media (max-width: 767px) {
    ._g-custom-checkbox .checkbox-holder {
        font-size: 15px;
        line-height: 15px;
    }
}
._g-custom-checkbox .checkbox-holder:hover {
    color: #060d45;
}
._g-custom-checkbox._mod-switch:hover .checkbox-holder {
    color: #40c067;
}
._g-custom-checkbox._mod-switch .checkbox-holder {
    padding-right: 60px;
    padding-left: 0;
    text-align: left;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: normal;
    color: #060d45;
}
._g-custom-checkbox._mod-switch .ch-btn {
    width: 52px;
    padding: 0 2px;
    height: 26px;
    left: auto;
    right: 0;
    background: #f1f6fd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 17px;
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-custom-checkbox._mod-switch .ch-btn:before {
    content: '';
    display: block;
    clear: both;
    width: 26px;
    height: 22px;
    border: none;
    background: #ffffff;
    border-radius: 23px;
    -webkit-transform: none;
    transform: none;
    margin: 0;
    opacity: 1;
    top: auto;
    left: auto;
    position: relative;
    -webkit-box-shadow: 0 1.48886px 5.95544px -2.60551px rgba(0, 0, 0, 0.16);
    box-shadow: 0 1.48886px 5.95544px -2.60551px rgba(0, 0, 0, 0.16);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-custom-checkbox._mod-switch input:checked + .checkbox-holder + .ch-btn {
    background: #ecf6fd;
}
._g-custom-checkbox._mod-switch
    input:checked
    + .checkbox-holder
    + .ch-btn:before {
    -webkit-transform: translateX(22px);
    transform: translateX(22px);
    background: #40c067;
}

._g-blue-arrowed-btn {
    background: #0a2333;
    font-size: 31px;
    letter-spacing: -1px;
    color: #fff;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
    border-radius: 14px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 40px 5px;
    background: #0a2333;
    position: relative;
    overflow: hidden;
}
@media (max-width: 767px) {
    ._g-blue-arrowed-btn {
        font-size: 20px;
        height: 52px;
        border-radius: 9px;
        padding: 0 20px 2px 35px;
        font-weight: 500;
        letter-spacing: 0;
    }
}
._g-blue-arrowed-btn:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    background: #415fd4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
}
._g-blue-arrowed-btn span {
    display: block;
    position: relative;
    z-index: 3;
}
._g-blue-arrowed-btn i {
    margin-left: 30px;
    display: block;
    position: relative;
    z-index: 3;
}
@media (max-width: 767px) {
    ._g-blue-arrowed-btn i {
        margin-left: 10px;
    }
    ._g-blue-arrowed-btn i img {
        max-width: 34px;
    }
}
._g-blue-arrowed-btn:hover:before {
    opacity: 1;
}
@media (min-width: 768px) {
    ._g-blue-arrowed-btn.size-m {
        height: 62px;
        font-size: 23px;
    }
}

._g-arrowed-round-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #0a2333;
    font-size: 25px;
    font-weight: 500;
}
@media (max-width: 767px) {
    ._g-arrowed-round-btn {
        font-size: 20px;
        padding-left: 20px;
    }
}
._g-arrowed-round-btn:hover {
    color: #060d45;
}
._g-arrowed-round-btn:hover i {
    background: #0a2333;
}
._g-arrowed-round-btn:hover i img {
    -webkit-filter: grayscale(1) brightness(3);
    filter: grayscale(1) brightness(3);
}
._g-arrowed-round-btn span {
    display: block;
}
._g-arrowed-round-btn i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 64px;
    height: 40px;
    border: 1px solid #0a2333;
    border-radius: 50px;
    padding-right: 3px;
    margin-left: 22px;
}
@media (max-width: 767px) {
    ._g-arrowed-round-btn i {
        padding-top: 1px;
    }
}
._g-arrowed-round-btn i img {
    width: 22px;
}

._g-link-blue-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    color: #40c067;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-link-blue-circle:hover {
    color: #060d45;
}
._g-link-blue-circle:hover i {
    background: #060d45;
}
._g-link-blue-circle span {
    display: block;
}
._g-link-blue-circle i {
    border-radius: 50%;
    background: #40c067;
    width: 23px;
    min-width: 23px;
    height: 23px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-link-blue-circle i img {
    width: 22px;
    min-width: 22px;
    margin-right: 8px;
}

._g-form-submit-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 27px;
    line-height: 25px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
    border-radius: 15px;
    height: 72px;
    width: 100%;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#6592e7),
        to(#2033b7)
    );
    background: linear-gradient(to right, #6592e7 0%, #2033b7 100%);
    overflow: hidden;
}
@media (max-width: 767px) {
    ._g-form-submit-btn {
        height: 52px;
        font-size: 19px;
        border-radius: 9px;
    }
}
._g-form-submit-btn:before {
    content: '';
    display: block;
    clear: both;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#4069d5),
        to(#1c128e)
    );
    background: linear-gradient(to right, #4069d5 0%, #1c128e 100%);
    border-radius: 15px;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-form-submit-btn:after {
    content: '';
    display: block;
    clear: both;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#375cb7),
        to(#160d73)
    );
    background: linear-gradient(to right, #375cb7 0%, #160d73 100%);
    border-radius: 15px;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-form-submit-btn:active:after {
    opacity: 1;
}
._g-form-submit-btn:hover:before {
    opacity: 1;
}
._g-form-submit-btn[disabled] {
    background: #d8dcf3;
}
._g-form-submit-btn[disabled]:before,
._g-form-submit-btn[disabled]:after {
    opacity: 0;
    visibility: hidden;
}
._g-form-submit-btn span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 3;
    padding-bottom: 3px;
}
._g-form-submit-btn i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 3;
    margin-left: 20px;
}
@media (max-width: 767px) {
    ._g-form-submit-btn i img {
        max-width: 25px;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.hidden-box {
    display: none;
}

._g-loader {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -100px;
    margin-left: -100px;
    width: 100px;
    height: 100px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
}
._g-loader:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: rgba(57, 108, 243, 0.54);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}
._g-loader:after {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #40c067;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
._g-loader span {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #bbc3d6;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

.back-link-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2vh;
}
.back-link-wrapper .back-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
}
@media (max-width: 767px) {
    .back-link-wrapper .back-link {
        font-size: 15px;
        font-weight: 500;
    }
}
.back-link-wrapper .back-link:hover i {
    background: #476df2;
    border-color: #476df2;
}
.back-link-wrapper .back-link:hover i img {
    -webkit-filter: none;
    filter: none;
}
.back-link-wrapper .back-link i {
    border: 1px solid #b1bbd1;
    border-radius: 17px;
    width: 64px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
@media (max-width: 767px) {
    .back-link-wrapper .back-link i {
        margin-right: 10px;
        height: 34px;
        width: 58px;
    }
}
.back-link-wrapper .back-link i img {
    -webkit-filter: invert(58%) sepia(14%) saturate(610%) hue-rotate(182deg)
        brightness(98%) contrast(93%);
    filter: invert(58%) sepia(14%) saturate(610%) hue-rotate(182deg)
        brightness(98%) contrast(93%);
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.back-link-wrapper .back-link span {
    color: #8493b2;
    display: block;
}

.warn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

._g-warn-whitebox {
    background: #ffffff;
    padding: 6px 20px 8px 13px;
    border-radius: 8px;
    color: #7f8193;
    font-size: 15px;
    font-weight: 300;
    line-height: 14px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
._g-warn-whitebox i {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.4;
    min-width: 20px;
    margin-right: 10px;
}
._g-warn-whitebox span {
    display: inline-block;
    vertical-align: middle;
}
._g-warn-whitebox._mod-red {
    background: #f2f4f9;
    color: #000;
    font-weight: normal;
}
._g-warn-whitebox._mod-red i {
    opacity: 1;
}
._g-warn-whitebox._mod-red i img {
    -webkit-filter: invert(22%) sepia(88%) saturate(7493%) hue-rotate(3deg)
        brightness(103%) contrast(123%);
    filter: invert(22%) sepia(88%) saturate(7493%) hue-rotate(3deg)
        brightness(103%) contrast(123%);
}

._g-custom-checkbox-on-off {
    position: relative;
    font-weight: normal;
}
._g-custom-checkbox-on-off input {
    opacity: 0;
    font-size: 0;
    visibility: hidden;
    position: absolute;
}
._g-custom-checkbox-on-off input:checked + .checkbox-holder .switcher:before {
    background: #40c067;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
._g-custom-checkbox-on-off input:checked + .checkbox-holder .switcher em {
    color: #ffffff;
}
._g-custom-checkbox-on-off
    input:checked
    + .checkbox-holder
    .switcher
    em:last-child {
    color: #99a8c5;
}
._g-custom-checkbox-on-off
    input:checked
    + .checkbox-holder
    .switcher
    em:last-child:hover {
    color: #000;
}
._g-custom-checkbox-on-off:hover .switcher em {
    color: #000;
}
._g-custom-checkbox-on-off .checkbox-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
._g-custom-checkbox-on-off .check-label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: block;
    font-size: 18px;
    line-height: 19px;
    padding-top: 3px;
}
._g-custom-checkbox-on-off .switcher {
    width: 118px;
    height: 37px;
    padding: 3px 3px;
    background: #eaf2fd;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}
._g-custom-checkbox-on-off .switcher:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 3px;
    background: #ffffff;
    -webkit-box-shadow: 0 2.07273px 8.29091px -3.62727px rgba(0, 0, 0, 0.16);
    box-shadow: 0 2.07273px 8.29091px -3.62727px rgba(0, 0, 0, 0.16);
    border-radius: 3.05455px;
    width: 48%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-custom-checkbox-on-off .switcher em {
    width: 48%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-style: normal;
    font-size: 13px;
    color: #99a8c5;
    font-weight: 500;
    position: relative;
    z-index: 3;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
._g-custom-checkbox-on-off .switcher em:last-child {
    color: #000;
}
._g-custom-checkbox-on-off .checkbox-label {
    margin: 0;
    height: 100%;
    display: block;
    font-weight: normal;
    font-size: 18px;
}
@media (min-width: 768px) {
    ._g-custom-checkbox-on-off._size-lg .switcher {
        width: 210px;
        height: 55px;
        border-radius: 10px;
    }
    ._g-custom-checkbox-on-off._size-lg .switcher:before {
        top: 3px;
        bottom: 3px;
        right: 4px;
        border-radius: 8px;
    }
    ._g-custom-checkbox-on-off._size-lg .switcher em {
        font-size: 19px;
    }
}
._g-custom-checkbox-on-off.disabled-checkbox .switcher {
    cursor: default;
    background: #fbfcfe;
}
._g-custom-checkbox-on-off.disabled-checkbox .switcher em {
    color: #99a8c5;
}
._g-custom-checkbox-on-off.disabled-checkbox .switcher em:last-child {
    color: #b3b3b3;
}

@media (max-width: 767px) {
    ._gXsScrollBar .scrollBarInner {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    ._gXsScrollBar ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    ._gXsScrollBar ::-webkit-scrollbar:horizontal {
        height: 5px;
    }
    ._gXsScrollBar ::-webkit-scrollbar-thumb {
        background-color: rgba(138, 142, 174, 0.6);
        border-radius: 10px;
    }
    ._gXsScrollBar ::-webkit-scrollbar-track {
        border-radius: 10px;
        margin: 0 6vw;
        background-color: rgba(138, 142, 174, 0.1);
    }
    ._gXsScrollBar._mod-top {
        position: relative;
    }
    ._gXsScrollBar._mod-top ::-webkit-scrollbar {
        position: absolute;
        top: 0;
        left: 0;
    }
}

._g-xs-flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.fib {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.fi {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 25px;
    line-height: 1em;
}
.fi:before {
    content: ' ';
}
.fi .fis {
    width: 1em;
}

.fi-ru {
    background-image: url(../shared/images/svg/flags/ru.svg);
}

.fi-es {
    background-image: url(../shared/images/svg/flags/es.png);
}

.fi-ar {
    background-image: url(../shared/images/svg/flags/ar.png);
}

.fi-pt {
    background-image: url(../shared/images/svg/flags/pt.png);
    margin-right: 0 !important;
}

.fi-tr {
    background-image: url(../shared/images/svg/flags/tr.svg);
}

.fi-us {
    background-image: url(../shared/images/svg/flags/us.svg);
}

.fi-cn {
    background-image: url(../shared/images/svg/flags/cn.svg);
}

.fi-ly {
    background-image: url(../shared/images/svg/flags/ly.svg);
}

.fi-de {
    background-image: url(../shared/images/svg/flags/de.svg);
}

._g-block-closed-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
    min-height: 200px;
}
@media (max-width: 767px) {
    ._g-block-closed-box {
        min-height: 100px;
    }
}
._g-block-closed-box .icon {
    margin-bottom: 25px;
}
._g-block-closed-box .closed-text {
    display: block;
    font-size: 24px;
    line-height: 24px;
    color: #a4aec0;
    text-align: center;
}
@media (max-width: 767px) {
    ._g-block-closed-box .closed-text {
        font-size: 18px;
        line-height: 18px;
    }
}

.container,
.main-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
    max-width: 100%;
}
@media (min-width: 1650px) {
    .container {
        padding: 0;
        width: 1440px;
    }
    .main-container {
        padding: 0;
        width: 1600px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .container,
    .main-container {
        padding-left: 4%;
        padding-right: 4%;
    }
}
@media (max-width: 767px) {
    .container,
    .main-container {
        padding-left: 6%;
        padding-right: 6%;
    }
}

.container-left {
    padding-left: 3vw;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .container-left {
        padding-left: 4vw;
    }
}

.container-right {
    padding-right: 3vw;
    width: 100%;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .container-right {
        padding-right: 4vw;
    }
}

._g-out-container {
    margin-left: -3vw;
    margin-right: -3vw;
}
@media (min-width: 1750px) {
    ._g-out-container {
        margin: 0 0 0 -50vw;
        left: 49.5%;
        width: 100vw;
        position: relative;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    ._g-out-container {
        margin-left: -4vw;
        margin-right: -4vw;
    }
}
@media (max-width: 767px) {
    ._g-out-container {
        margin-left: -6vw;
        margin-right: -6vw;
    }
}

.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

.fade.in {
    opacity: 1;
}

.collapse {
    display: none;
    visibility: hidden;
}

.collapse.in,
.collapse.show {
    display: block;
    visibility: visible;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
}

.tab-content > .tab-pane {
    display: none;
}
.tab-content > .active {
    display: block;
    opacity: 1;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    display: block;
    //overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition:
        transform 0.3s ease-out,
        -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    margin: 0 auto;
    width: 50%;
}

@media (max-width: 767px) {
    .modal-dialog {
        width: 70%;
    }
}

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: 0;
    margin-top: 100px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    line-height: 1.42857143;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-circle {
    border-radius: 50%;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    display: table;
    content: ' ';
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.affix {
    position: fixed;
}

@-ms-viewport {
    width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
}
@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type='search'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input[type='radio'],
input[type='checkbox'] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type='file'] {
    display: block;
}

input[type='range'] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition:
        border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition:
        border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999;
}

.form-control::-webkit-input-placeholder {
    color: #999;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #eee;
    opacity: 1;
}

textarea.form-control {
    height: auto;
}

input[type='search'] {
    -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='date'],
    input[type='time'],
    input[type='datetime-local'],
    input[type='month'] {
        line-height: 34px;
    }
}
.form-group {
    margin-bottom: 15px;
}

.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.radio label,
.checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
    cursor: not-allowed;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
    cursor: not-allowed;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}
.row > * .col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
}

.row-cols-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
}

.row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}

.row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
}

.row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
}

.row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}

.offset-1 {
    margin-left: 8.33333333%;
}

.offset-2 {
    margin-left: 16.66666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333333%;
}

.offset-5 {
    margin-left: 41.66666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333333%;
}

.offset-8 {
    margin-left: 66.66666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333333%;
}

.offset-11 {
    margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
    .col-sm {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-sm-auto > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333333%;
    }

    .offset-sm-2 {
        margin-left: 16.66666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333333%;
    }

    .offset-sm-5 {
        margin-left: 41.66666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333333%;
    }

    .offset-sm-8 {
        margin-left: 66.66666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333333%;
    }

    .offset-sm-11 {
        margin-left: 91.66666667%;
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0;
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0;
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem;
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem;
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem;
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem;
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem;
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 768px) {
    .col-md {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-md-auto > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333333%;
    }

    .offset-md-2 {
        margin-left: 16.66666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333333%;
    }

    .offset-md-5 {
        margin-left: 41.66666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333333%;
    }

    .offset-md-8 {
        margin-left: 66.66666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333333%;
    }

    .offset-md-11 {
        margin-left: 91.66666667%;
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0;
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0;
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem;
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem;
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem;
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem;
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem;
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 992px) {
    .col-lg {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-lg-auto > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333333%;
    }

    .offset-lg-2 {
        margin-left: 16.66666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333333%;
    }

    .offset-lg-5 {
        margin-left: 41.66666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333333%;
    }

    .offset-lg-8 {
        margin-left: 66.66666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333333%;
    }

    .offset-lg-11 {
        margin-left: 91.66666667%;
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0;
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0;
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem;
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem;
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem;
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem;
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem;
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 1200px) {
    .col-xl {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-xl-auto > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333333%;
    }

    .offset-xl-2 {
        margin-left: 16.66666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333333%;
    }

    .offset-xl-5 {
        margin-left: 41.66666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333333%;
    }

    .offset-xl-8 {
        margin-left: 66.66666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333333%;
    }

    .offset-xl-11 {
        margin-left: 91.66666667%;
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0;
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0;
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem;
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem;
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem;
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem;
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem;
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 1400px) {
    .col-xxl {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }

    .row-cols-xxl-auto > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-xxl-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-xxl-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xxl-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-xxl-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-xxl-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-xxl-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%;
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0;
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0;
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem;
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem;
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem;
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem;
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem;
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem;
    }
}
