@import "src/styles/variables";
.datePicker {
  padding: 10px 16px;
  border-radius: 10px;
  background-color: var(--color-card);
  color: var(--color-card-text);
  cursor: pointer;
  display: inline-block;
  &__wrapper {
    @include flex(center);
    gap: 16px;
    span {
      line-height: normal;
    }
  }
}
@media (max-width: 768px) {
  .datePicker {
    &__wrapper {
      @include flex(center);
      gap: 16px;
      span {
        font-size: map-get($font-size, small);
      }
    }
  }
}