@import "src/styles/variables";

.btn {
  color: var(--color-list-item);
  border-radius: 15px;
  font-weight: 600;
  padding: 8px 20px;
  font-size: map-get($font-size, medium);
  &.outlined {
    background-color: var(--background-btn);
    color: var(--color-btn);
  }
  &.normal {
    padding: 5px;
    font-weight: 500;
    font-size: map-get($font-size, regular);
  }
  &.general {
    background-color: map-get($colors, green);
    font-size: map-get($font-size, regular);
    font-weight: 600;
    color: white;
    padding: 11px 30px;
  }
  &.text {
    color: var(--color-text);
    background-color: transparent;
  }
  &.disabled {
    background-color: map-get($colors, light-gray);
    color: #AEAEB7;
  }
  &.middle {
    @include flex(center);
    gap: 8px;
  }
  svg {
    display: inline-block;
    margin-left: 10px;
    path {
      fill: var(--color-btn);
    }
  }
}

@media (max-width: 768px) {
  .btn {
    padding: 8px 10px;
    font-size: map-get($font-size, regular);
    &.normal {
      font-size: map-get($font-size, small);
    }
    &.general {
      font-size: map-get($font-size, small);
    }
    &.disabled {
      background-color: map-get($colors, light-gray);
      color: #AEAEB7;
    }
    svg {
      width: 20px;
    }
  }
}