@import "src/styles/variables";

.footer {
  padding: 44px 0;
  background: #000;
  &__container{
    width: 100%;
    max-width: 1800px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 2.5%;
  }

  &__inner {
    @include flex(flex-start, space-between);
    &> div {
      width: 25%;
    }
    &__logo {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 25px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;

      &__buttons_wrapper{
        display: flex;
        flex-direction: column;
        gap: 15px;
        a {
          @include btn();
          display: inline-block;
          padding: 10px 30px;
          color: #fff;
          border: 1px solid #ffffff;

          &.active{
            color: #ffffff;
            border: 1px solid #40C067;
          }
        }
      }
      p {
        color: #fff;
      }
    }
    &__links{
      a {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        &:hover{
          text-decoration: underline;
        }
      }
      h1 {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
      ul {
        margin-top: 20px;
        li {
          margin-bottom: 12px;
        }
      }
    }
    &__social {
      h1 {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
      &__logos {
        margin-top: 20px;
        margin-bottom: 20px;
        @include flex(center, unset);
        gap: 10px;
        a {
          @include flex(center, center);
          width: 42px;
          height: 42px;
          cursor: pointer;
          transition: filter 0.5s;
          filter: brightness(400%);
          &:hover {
            filter: none;
          }
        }
      }

    }
  }
}
@media (max-width: 768px){
  .footer{
    .footer__container{
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
    &__inner{
      flex-direction: column;
      gap: 40px;
      &> div:not(:last-child) {
        width: 100%;
        border-bottom: 1px solid #565656;
        padding: 20px 6%;
      }
      &__social{
        width: 100%;
        max-width: 100%;
        min-width: 75%;
        margin: 20px 6%;

        &__logos{
          width: 100%;
          align-items: center;

          a{
            width: auto;
            height: auto;
          }
          img{
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}