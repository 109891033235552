/*bold*/
@font-face {
	font-family: 'Aeroport_webfont';
	src: url('Aeroport-Bold.woff2') format('woff2'),
	url('Aeroport-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
/*medium*/
@font-face {
	font-family: 'Aeroport_webfont';
	src: url('Aeroport-Medium.woff2') format('woff2'),
	url('Aeroport-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
/*regular*/
@font-face {
	font-family: 'Aeroport_webfont';
	src: url('Aeroport.woff2') format('woff2'),
	url('Aeroport.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
/*light*/
@font-face {
    font-family: 'Aeroport_webfont';
    src: url('Aeroport-Light.woff2') format('woff2'),
        url('Aeroport-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/*bold*/
@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Bold.ttf') format('truetype'),
	url('Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
/*semibold*/
@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBold.ttf') format('truetype'),
	url('Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
/*medium*/
@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Medium.ttf') format('truetype'),
	url('Montserrat-Medium.ttf') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
/*regular*/
@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Medium.ttf') format('truetype'),
	url('Montserrat-Medium.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
/*light*/
@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Light.ttf') format('truetype'),
	url('Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBold.ttf') format('truetype'),
	url('Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}










