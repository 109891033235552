.popup {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  background-color: #fdeded;
  margin-bottom: 20px;
  width: 35%;
  border-radius: 10px;
  &__content {
    &__title {
      font-weight: 600;
    }
    &__description {
      font-size: 14px;
    }
  }
  &.warning {
    background-color: lightyellow !important;
  }
  &.success {
    background-color: #d2ebb0 !important;
  }
}

@media (max-width: 1024px) {
  .popup {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .popup {
    width: 90%;
    &__content {
      &__title {}
    }
  }
}