@mixin flex($align: unset, $justify: unset) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}
@mixin card() {
  .mobile {
    display: none;
  }
  .customCard {
    padding: 15px;
    background-color: var(--color-card);
    font-size: map-get($font-size, small);
    border-radius: 10px;
    margin-bottom: 10px;
    @include flex(center);
    &__content {
      flex: 2;
      &__heading {
        @include flex(center);
        &__status {
          width: 7px;
          height: 7px;
          margin-right: 10px;
          display: inline-block;
          border-radius: 100%;
          &.green {
            background-color: map-get($colors, green);
          }
          &.red {
            background-color: red;
          }
        }
        &__title {
          font-weight: 600;
          margin-right: 20px;
          font-size: map-get($font-size, regular);
        }
      }
      &__bottom {
        @include flex(center, flex-start);
        gap: 10px;
        margin-top: 5px;
        &__header {
          color: map-get($colors, black2);
          font-size: map-get($font-size, small);
        }
        &__value {
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
    &__icon {
      width: fit-content;
      @include flex(center, center);
      img {
        transform: rotate(-90deg);
      }
      &.reverse {
        img {
          transform: rotate(0deg);
        }
      }
    }
  }
}
@mixin btn() {
  padding: 10px 20px;
  font-style: normal;
  font-weight: 600;
  border-radius: 13px;
  text-align: center;
  &.active{
    background-color: #40C067;
    color: white;
  }

}

$colors: (
  green: #40C067,
  aqua: #DDF6EB,
  gray: #EDECEC,
  light-gray: #f4f4f9,
  light-gray2: #FAFAFC,
  light-green: #DDF6EB,
  gray2: #D5D5D5,
  gray3: #7A7B7B,
  red: #F63131,
  black2: #757878,
  orange: #F7931A,
  black: #202224,
  black3: #353636
);
$font-size: (
  tiny: 12px,
  small: 14px,
  regular: 16px,
  normal: 18px,
  medium: 20px,
  big: 24px,
  big2: 32px,
  large: 36px,
)