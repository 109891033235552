@import "src/styles/variables";
.loader {
  width: 36px;
  height: 36px;
  border: 5px solid map-get($colors, green);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.center {
  @include flex(center, center);
  margin: 10px 0;
}