@import 'src/styles/variables';
.sidebar {
    width: 300px;
    height: 100vh;
    position: relative;
    background-color: var(--color-card);
    color: var(--color-text);
    &__icon {
        margin-top: 25px;
        text-align: center;
    }
    &__content {
        margin-top: 14px;
        padding: 0px 20px;
        @include flex();
        flex-direction: column;
        height: calc(100% - 80px);
        @mixin activeLink() {
            background-color: var(--background-item);
            color: var(--color-list-item);
            svg {
                path {
                    stroke: var(--color-list-item);
                }
            }
        }
        & > div {
            &:last-child {
                height: 100%;
                @include flex(unset, space-between);
                flex-direction: column;
            }
        }
        &__navbar {
            margin-top: 16px;
            & > a {
                display: flex;
                padding: 16px;
                color: var(--color-text);
                margin-bottom: 10px;
                border-radius: 14px;
                white-space: nowrap;
                &:hover {
                    @include activeLink;
                }
                &.active {
                    @include activeLink;
                }
                & > span {
                    margin-left: 10px;
                    display: inline-block;
                }
            }
        }
        &__bottomBar {
            & > a {
                color: var(--color-icon);
                & > svg {
                    path {
                        stroke: var(--color-icon);
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .sidebar {
        display: none;
    }
}
