@import "src/styles/variables";
.input, .select {
  border: 1px solid map-get($colors, black2);
  background-color: var(--background);
  padding: 10px 20px;
  border-radius: 10px;
  color: 1px solid var(--color-card-text);
  font-size: map-get($font-size, small);
  width: 100%;
}
.wrapper{
  &>div {
    position: relative;
    .padding {
      padding-right: 30px;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      cursor: pointer;
    }
  }
  label {
    font-size: map-get($font-size, tiny);
    color: map-get($colors, black2);
    display: block;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.error {
  color: map-get($colors, red);
  font-size: map-get($font-size, tiny);
}