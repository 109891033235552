@import "@/styles/_variables.scss";
.headerTop {
  position: sticky;
  width: 100%;
  background-color: #000;
  color: #fff;
  top: 0;
  z-index: 6;
  border-bottom: 1px solid #6a6a6a;
  &__container{
    width: 100%;
    max-width: 1800px;
    padding: 0 2.5%;
    margin-right: auto;
    margin-left: auto;
  }
}
.header {
  &__center{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__left,
  &__right{
    min-width: 335px;
    width: 100%;
    max-width: 425px;
  }
  &__right{
    display: flex;
    justify-content: end;
    align-items: center;
    min-width: 425px;
  }
  @include flex(stretch, justify-between);
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  &__left {
    display: flex;
    align-items: center;
    gap: 40px;
    &__logo {}
    &__links {
      display: flex;
      align-items: center;
      gap: 40px;
      a {
        color: inherit;
        font-weight: 500;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    &__btns {
      @include flex(center, unset);
      gap: 16px;
      &__lang {
        position: relative;
        button {
          border-radius: 8px;
          background: transparent;
          border: 1px solid transparent;
          text-transform: uppercase;
          font-weight: 700;
          color: inherit;
          padding: 9px 16px;
          @include flex(center, unset);
          gap: 10px;
        }
      }
      &__links{
        display: flex;
        align-items: center;
      }
      &__links > button {
        border-radius: 10px;
        border: 1px solid #ffffff;
        padding: 5px 25px;
        text-wrap: none;
        font-weight: 600;
        color: #ffffff;
        &:last-child {
          margin-left: 5px;
        }
        &.active {
          background-color: #ffffff;
          color: #000000;
        }
      }
    }
  }
}
@media (max-width: 1220px){
  .header {
    &__left,
    &__right {
      min-width: 260px;
      width: 100%;
      max-width: 425px;

      &__btns {
        gap: 5px;

        &__links button {
          font-size: 14px;
          padding: 5px 5px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .header {
    justify-content: space-between;

    &__left,
    &__right {
      min-width: auto;
      width: auto;
      max-width: none;
    }
    &__left {
      &__links {
        display: none;
      }
    }
    &__right {
      &__btns {
        &__lang{
          //display: none;
        }
        &__links {
          display: none;
        }
      }
    }
  }
}