@import "src/styles/variables";
.dialog {
  background-color: rgba(map-get($colors, black), 0.5);
  @include flex(center, center);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  &__wrapper {
    border-radius: 15px;
    background-color: var(--color-card);
    width: 700px;
    padding: 20px;
    @media (max-width: 768px) {
      width: 400px;
    }
    * {
      color: var(--color-card-text);
      path {
        fill: var(--color-card-text);
      }
    }
    & > h2 {
      color: map-get($colors, green);
      text-align: center;
      font-size: map-get($font-size, big);
      font-weight: 600;
      position: relative;
      span {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        position: absolute;
      }
    }
    &.large {
      max-width: 700px;
    }
    &.medium {
      max-width: 500px;
    }
    &.tiny {
      max-width: 300px;
    }
  }
}