@import "src/styles/variables";
.settings {
  display: none;
  & > h1  {
    font-size: map-get($font-size, regular);
    font-weight: 700;
  }
  &__list {
    margin-bottom: 20px;
    margin-top: 10px;
    &__item {
      @include flex(center);
      padding: 10px 15px;
      margin-bottom: 10px;
      border-radius: 10px;
      gap: 22px;
      &__icon {
        padding: 5px;
        background-color: var(--background-item);
        border-radius: 6px;
        &.logout {
          background-color: var(--color-active-text);
        }
      }
      p {
        font-size: map-get($font-size, small);
        font-weight: 600;
      }
    }
  }
}
@media (max-width: 768px) {
  .settings {
    display: block;
  }
}