.auth__decorations__lines{
    pointer-events: none;
    position: absolute;
    z-index: 1;
    width: 50% !important;
    height: auto !important;

    &.upleftline{
        top: 0;
        left: 0;
    }
    &.downrightline{
        bottom: 0;
        right: 0;
    }
}

@media (max-width: 991px){
    .auth__decorations__lines{
        display: none;
    }
}


.login-page-header {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    z-index: 5;
}
@media (max-width: 767px) {
    .login-page-header {
        top: 23px;
    }
}
.login-page-header .header-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 12px;
}
@media (max-width: 767px) {
    .login-page-header .header-holder {
        padding-top: 0;
    }
}
.login-page-header .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding-left: 2%;
}
.login-page-header .logo-box .logo {
    font-weight: bold;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 35px;
}
@media (max-width: 767px) {
    .login-page-header .logo-box .logo {
        font-size: 23px;
    }
}
.login-page-header .logo-box .logo i {
    display: block;
    margin-right: 12px;
    width: 37px;
}
@media (max-width: 767px) {
    .login-page-header .logo-box .logo i {
        margin-right: 9px;
        width: 28px;
    }
}
.login-page-header .logo-box .logo img {
    width: 100%;
}
.login-page-header .logo-box .logo span {
    display: block;
}
.login-page-header .logo-box .slogan {
    display: block;
    font-size: 13px;
    line-height: 12px;
    color: #fff;
    padding-left: 20px;
    padding-top: 6px;
    font-weight: 300;
}

.sign-up-page {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: 80% 100%;
    background-position: left center;
}
@media (min-width: 768px) and (max-width: 991px) {
    .sign-up-page {
        height: 100%;
        min-height: 100dvh;
        background-size: cover;
    }
}
@media (max-width: 767px) {
    .sign-up-page {
        background-size: cover;
    }
}
.sign-up-page .pagebg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}
.sign-up-page .pagebg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0% 0%;
    object-position: 0 0%;
}
@media (min-width: 768px) and (max-width: 991px) {
    .sign-up-page .pagebg img {
        -o-object-position: 50% 70%;
        object-position: 50% 70%;
    }
}
@media (max-width: 767px) {
    .sign-up-page .pagebg img {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 0;
        object-position: 50% 0;
    }
    .sign-up-page {
        min-height: 100dvh;
    }
}
.sign-up-page .login-content {
    height: 100%;
    position: relative;
    z-index: 3;
}
.sign-up-page .login-content .container {
    height: 100%;
}
.sign-up-page .login-content-row {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
@media (min-width: 768px) and (max-width: 991px) {
    .sign-up-page .login-content-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
@media (max-width: 767px) {
    .sign-up-page .login-content-row {
        display: block;
        height: 100%;
        //margin-top: 30px;
    }
    .login-with-header .login-content-row {
        display: flex;
        flex-direction: column;
        height: 100%;
        //margin-top: 100px;
    }
    .login-content {
        overflow: hidden;
        //overflow-y: scroll;
    }
}
.sign-up-page .left-content-col {
    //padding: 5vh 30px 5vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: #ffffff;

    svg{
        width: 450px;
        height: 200px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sign-up-page .left-content-col {
        width: 100%;
        padding-bottom: 30px;
    }
}
@media (max-width: 767px) {
    .sign-up-page .left-content-col {
        padding: 0;
    }
}
.sign-up-page .left-content-col .content-holder {
    height: 100%;
    padding: 80px 0 7vh 2vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    max-height: 1000px;
    & > button {
        margin-top: 10px;
        width: 70%;
        height: 50px;
    }

    & > h1 {
        white-space: pre-line;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sign-up-page .left-content-col .content-holder {
        padding-bottom: 0;
    }
}
@media (max-width: 767px) {
    .sign-up-page .left-content-col .content-holder {
        padding: 0;
        display: block;
    }
}
.sign-up-page .left-content-col h1 {
    font-size: 100px;
    line-height: 80px;
    letter-spacing: -3px;
    font-weight: bold;
    margin: 0 0 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .sign-up-page .left-content-col h1 {
        font-size: 80px;
        line-height: 70px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sign-up-page .left-content-col h1 {
        margin-bottom: 20px;
    }
    .sign-up-page .left-content-col h1 br {
        display: none !important;
    }
}
@media (max-width: 767px) {
    .sign-up-page .left-content-col h1 {
        font-size: 37px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 15px;
    }
}
.sign-up-page .left-content-col span {
    display: block;
    font-size: 19px;
    letter-spacing: -1px;
    font-weight: 300;
}
.sign-up-page .right-content-col {
    height: 100%;
    min-height: 100dvh;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #101010;
    width: 50%;
}
@media (min-width: 768px) and (max-width: 1001px) {
    .sign-up-page .right-content-col {
        padding-top: 0;
        min-height: 0;
        width: 70%;
        margin-left: unset;
    }
}
@media (max-width: 767px) {
    .sign-up-page .right-content-col {
        min-height: 0;
        margin: 0;
        width: auto;
    }
}

.main-login-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 7px;
    padding: 25px;
    width: 540px;
    height: 100%;
    .mainIcon {
        display: none;
        cursor: pointer;
        margin-bottom: 40px;
        text-align: center;
        img {
            width: 160px;
        }
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .main-login-form {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (max-width: 1020px) {
    .main-login-form {
        width: auto;
        height: 100%;
    }
}
@media (max-width: 767px) {
    .main-login-form {
        padding: 25px 6vw 40px;
        border-radius: 20px;
        height: 100%;
        width: auto;
    }
}
@media (min-width: 992px) and (max-height: 800px) {
    .main-login-form.sign-up .form-name {
        font-size: 60px;
    }
    .main-login-form.sign-up .form-name br {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-height: 870px) {
    .main-login-form.sign-up .form-group {
        margin-bottom: 15px;
    }
}
@media (min-width: 992px) and (max-height: 870px) {
    .main-login-form.sign-up input.form-control {
        height: 60px;
    }
}
.main-login-form .form-heading {
    margin-bottom: 30px;
    text-align: center;
}
.main-login-form .form-name {
    font-size: 25px;
    font-weight: 700;
    color: white;
    display: block;
    white-space: pre-line;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .main-login-form .form-name {
        font-size: 58px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .main-login-form .form-name {
        font-size: 58px;
        line-height: 45px;
    }
    .main-login-form .form-name br {
        display: none !important;
    }
}
@media (max-width: 767px) {
    .main-login-form .form-name {
        font-size: 36px;
        line-height: 30px;
    }
}
.main-login-form .form-fields {
    padding-bottom: 20px;
    p {
        text-align: left;
        padding-bottom: 10px;
        color: gray;
        span {
            color: #40c067;
        }
    }
}
.main-login-form .form-group {
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .main-login-form .form-group {
        margin-bottom: 15px;
    }
}
.main-login-form .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
@media (max-width: 767px) {
    .main-login-form .form-row {
        display: block;
    }
}
.main-login-form .form-row .col {
    width: 48%;
}
@media (max-width: 767px) {
    .main-login-form .form-row .col {
        width: 100%;
        margin-bottom: 15px;
    }
    .main-login-form .form-row .col:last-child {
        margin-bottom: 0;
    }
}
.main-login-form input.form-control {
    border: 1px solid #acacb4;
    border-radius: 10px;
    height: 68px;
    padding: 0 20px 0 23px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #060d45;
    font-size: 16px;
    line-height: 16px;
    &.error {
        border: 1px solid red;
    }
    & + .error {
        color: red;
        display: inline-block;
        margin-top: 10px;
    }
}
.form-group > span.error {
    color: red;
    display: inline-block;
    margin-top: 10px;
}
@media (max-width: 767px) {
    .main-login-form input.form-control {
        height: 52px;
        font-size: 16px;
        line-height: 16px;
        border-radius: 8px;
        padding-left: 20px;
    }
}
.main-login-form input.form-control:hover {
    border-color: #40c067;
}
.main-login-form input.form-control:focus {
    border-color: #40c067;
    -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.08),
        0 0 8px rgba(176, 196, 250, 0.5);
    box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.08),
        0 0 8px rgba(176, 196, 250, 0.5);
}
.main-login-form input.form-control::-webkit-input-placeholder {
    color: #626899;
}
.main-login-form input.form-control:-moz-placeholder {
    color: #626899;
}
.main-login-form input.form-control::-moz-placeholder {
    color: #626899;
}
.main-login-form input.form-control:-ms-input-placeholder {
    color: #626899;
}
.main-login-form .add-link {
    display: inline-block;
    font-size: 18px;
    color: #40c067;
}
.main-login-form .add-link:hover {
    color: #060d45;
}
.main-login-form .password-input {
    position: relative;
}
.main-login-form .password-input.size-long i {
    right: 10px;
}
.main-login-form .password-input i {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    padding-bottom: 5px;
}
.main-login-form .password-input i:before {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    width: 22px;
    height: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #ccd2e3;
    border-bottom: 2px solid #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.main-login-form .password-input i:hover:before {
    background: #90939f;
}
.main-login-form .password-input i:hover img {
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
}
.main-login-form .password-input i img {
    width: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.main-login-form .jsPasswordsWithEye.activedEye .password-input i:before {
    opacity: 0;
}
.main-login-form ._g-warn-box {
    white-space: pre-line;
    margin-left: 25px;
    padding-bottom: 10px;
    text-align: left;
}
@media (max-width: 767px) {
    .main-login-form ._g-warn-box {
        margin-left: 0;
    }
}
.main-login-form ._g-warn-box span {
    color: #7a7d93;
    font-weight: 300;
}
.main-login-form ._g-blue-arrowed-btn {
    width: 100%;
}
.main-login-form .form-submit-wrap {
    padding-top: 10px;
    margin-bottom: 30px;
}
@media (max-width: 767px) {
    .main-login-form .form-submit-wrap {
        padding-top: 0;
    }
}
.main-login-form .form-submit-wrap button {
    white-space: nowrap;
    font-size: 18px;
    background-color: #40c067;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    color: white;
}
.main-login-form .form-submit-wrap button i {
    min-width: 50px;
}
.main-login-form .form-bottom .link-subtext {
    display: block;
    margin-bottom: 10px;
    color: #7a7d93;
    text-align: center;
    font-size: 18px;
}
@media (max-width: 767px) {
    .main-login-form .form-bottom .link-subtext {
        font-size: 15px;
        margin-bottom: 5px;
    }
}
.main-login-form .form-bottom .arrowed-btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    border: 1px solid #40c067;
    color: white;
    cursor: pointer;
    font-size: 18px;
    a {
        font-size: 18px;
    }
}
@media (max-width: 991px){
    .main-login-form {
        .mainIcon {
            display: block;
        }
    }
    .sign-up-page, .login-with-header{
        background-color: #101010;
    }
    .left-content-col{
        display: none !important;
    }
}