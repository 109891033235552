@import 'src/styles/variables';
.header {
    background-color: var(--color-card);
    padding: 16px;
    color: var(--color-text);
    @include flex(center, space-between);
    &__observer {
        &__logo {
            margin-right: 15px;
            display: inline-block;
        }
    }
    &__left {
        &__logo {
            display: none;
        }
    }
    &__right {
        @include flex(center, center);
        gap: 20px;
        &__lang {
            position: relative;
            span {
                color: var(--color-text);
            }
        }
        &__dropdown {
            display: none;
        }
        &__currency {
            position: relative;
            button {
                background-color: #F4F4F9;
                display: flex;
                gap: 20px;
                align-items: center;
                padding: 10px;
                border-radius: 10px;
            }
        }
        &__user {
            border-radius: 100%;
            background-color: map-get($colors, aqua);
            width: 35px;
            height: 35px;
            text-transform: capitalize;
            @include flex(center, center);
            padding: 10px;
        }
    }
}
@media (max-width: 768px) {
    .header {
        &__left {
            & > span {
                display: none;
            }
            &__logo {
                display: block;
            }
        }
        &__right {
            gap: 10px;
            flex-wrap: wrap;
            &__lang {
                display: none;
            }
            &__user {
                display: none;
            }
            &__dropdown {
                display: block;
            }
            &__currency {
                display: none;
            }
        }
    }
}
